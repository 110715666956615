







import { Component, Vue }       from 'vue-property-decorator'
import { LwLandingAnalytics } from '@/lw-landing.analytics'
import LwFooter               from './components/lw.footer.vue'

@Component({
  name: 'app',
  components: { LwFooter },
})
export default class App extends Vue {
  created() {
    document.title = 'LolWiz';
  }
  mounted(){
    // done here because it triggers the initial page view
    // to be sure that we have the routing considered /for influencer as source)
    // we do it separately from LwLandingAnalytics.init()
    // once the initial routing is confirmed.
    LwLandingAnalytics.initGtagConfig()
  }
}
