











import { Component, Vue } from "vue-property-decorator"
import { URL_LW_DOWNLOAD_BASE } from "@/constants"
import { LwLandingAnalytics } from "@/lw-landing.analytics"

@Component({
  name: "lw-button-download",
  components: {},
})
export default class LwButtonDownload extends Vue {
  protected get link() {
    return (
      URL_LW_DOWNLOAD_BASE +
      "&" +
      LwLandingAnalytics.getCampaignQueryForOverwolf()
    )
  }

  protected onClick() {
    LwLandingAnalytics.sendDownload()
  }
}
