import { ILwInfluencer } from "./types";

export const URL_LW_DOWNLOAD_BASE = "https://download.overwolf.com/install/Download?Name=LoLwiz&ExtensionId=dmpfhbhjknfmncjinjaikncjjnklcplnodamiimn&Channel=website"

export const influencers: ILwInfluencer[] = [
  {
    name: 'RYSCU',
    urlFragment: 'ryscu',
    avatar: 'avatar_ryscu.png',
    youtubeUrl: 'https://www.youtube.com/c/Ryscu'
  },
  {
    name: 'RAV',
    urlFragment: 'rav',
    avatar: 'avatar_rav.png',
    banner: 'banner_rav.png',
    youtubeUrl: 'https://www.youtube.com/c/Ravlol'
  }
]