var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lw-home"},[_c('div',{staticClass:"hero",style:({
      'background-image': 'url(' + require('@/assets/img/hero-bg.png') + ')',
    })},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"hero-content"},[_c('div',{staticStyle:{"z-index":"3"}},[_c('img',{staticClass:"logo",attrs:{"src":require('@/assets/img/lolwiz-logo.png'),"alt":"LolWiz Logo"}}),_c('div',{staticClass:"hero-description"},[_vm._v(" Struggling to improve your game? Turn the odds in your favor, with LoLwiz!! ")]),_c('div',{staticClass:"has-text-centered",staticStyle:{"display":"inline-block"}},[_c('lw-button-download',{staticClass:"mt-6"},[_vm._v("Download Now")]),_c('div',{staticClass:"has-text-mute mt-2 is-size-6"},[_vm._v(" FREE to use and Riot compliant ")])],1)]),_c('div',{staticClass:"hero-art",staticStyle:{"z-index":"2"}},[_c('img',{attrs:{"src":require('@/assets/img/app-art.png'),"alt":"LolWiz app window"}})]),(_vm.influencer)?_c('div',{staticClass:"influencer-card-wrap"},[_c('lw-influencer-card',{attrs:{"influencer":_vm.influencer}})],1):_vm._e()])]),_c('div',{staticClass:"clouds light",style:({
        'background-image':
          'url(' + require('@/assets/img/clouds-light-wide.png') + ')',
      })}),_c('div',{staticClass:"clouds dark",style:({
        'background-image':
          'url(' + require('@/assets/img/clouds-dark-wide.png') + ')',
      })})]),_c('div',{staticClass:"container my-6"},[_c('div',{staticClass:"is-size-4 has-text-centered mb-6 section-title"},[_vm._v("Features")]),_c('div',{staticClass:"features is-flex align-items-center"},[_c('div',{staticClass:"feature has-text-centered"},[_c('div',{staticClass:"feature-art mb-4"},[_c('lw-viewable-image',{attrs:{"src":require('@/assets/img/feature-1.png'),"alt":"Champ Select Assistance"},on:{"view-image":function($event){return _vm.viewImage($event)}}})],1),_c('div',{staticClass:"feature-title is-size-4 mb-2"},[_vm._v(" Champ Select Assistance ")]),_c('div',{staticClass:"feature-description is-size-6"},[_vm._v(" Get meta-based ban & pick tips and optimized runes based on your role and champion ")])]),_c('div',{staticClass:"feature has-text-centered"},[_c('div',{staticClass:"feature-art mb-4"},[_c('lw-viewable-image',{attrs:{"src":require('@/assets/img/feature-2.png'),"alt":"Superior Intelligence"},on:{"view-image":function($event){return _vm.viewImage($event)}}})],1),_c('div',{staticClass:"feature-title is-size-4 mb-2"},[_vm._v("Superior Intelligence")]),_c('div',{staticClass:"feature-description is-size-6"},[_vm._v(" Reveal strengths and weaknesses on BOTH teams in easy to understand tags ")])]),_c('div',{staticClass:"feature has-text-centered"},[_c('div',{staticClass:"feature-art mb-4"},[_c('lw-viewable-image',{attrs:{"src":require('@/assets/img/feature-3.png'),"alt":"Live Match Benchmarks"},on:{"view-image":function($event){return _vm.viewImage($event)}}})],1),_c('div',{staticClass:"feature-title is-size-4 mb-2"},[_vm._v("Live Match Benchmarks")]),_c('div',{staticClass:"feature-description is-size-6"},[_vm._v(" All new real-time stats give you gold, item builds, and team domination info ")])])])]),_c('div',{staticClass:"steps-wrapper",style:({
      'background-image': 'url(' + require('@/assets/img/steps-bg.png') + ')',
    })},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"is-size-4 has-text-centered mb-6 section-title"},[_vm._v(" Using LoLwiz in 3 steps ")]),_c('div',{staticClass:"steps is-flex align-items-center"},[_c('div',{staticClass:"step has-text-centered"},[_c('div',{staticClass:"step-art mb-4"},[_c('img',{attrs:{"src":require('@/assets/img/step-icon-1.png'),"alt":"Download LoLwiz"}})]),_c('div',{staticClass:"step-title is-size-4 mb-2"},[_vm._v("Download LoLwiz")]),_c('div',{staticClass:"step-description is-size-6"},[_vm._v(" You can get it securely for free using the button below ")])]),_c('div',{staticClass:"step has-text-centered"},[_c('div',{staticClass:"step-art mb-4"},[_c('img',{attrs:{"src":require('@/assets/img/step-icon-2.png'),"alt":"Install LoLwiz"}})]),_c('div',{staticClass:"step-title is-size-4 mb-2"},[_vm._v("Install LoLwiz")]),_c('div',{staticClass:"step-description is-size-6"},[_vm._v(" A short installation will enable the app on your machine ")])]),_c('div',{staticClass:"step has-text-centered"},[_c('div',{staticClass:"step-art mb-4"},[_c('img',{attrs:{"src":require('@/assets/img/step-icon-3.png'),"alt":"Start Your Match"}})]),_c('div',{staticClass:"step-title is-size-4 mb-2"},[_vm._v("Start Your Match")]),_c('div',{staticClass:"step-description is-size-6"},[_vm._v(" The app automatically launches when your match starts ")])])])])]),_c('b-modal',{staticClass:"image-viewer-modal",attrs:{"scroll":"keep"},model:{value:(_vm.isImageModalActive),callback:function ($$v) {_vm.isImageModalActive=$$v},expression:"isImageModalActive"}},[_c('img',{attrs:{"src":_vm.modalImage,"srcset":_vm.modalImage}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }