





















import { Component, Vue } from 'vue-property-decorator'
import LwButtonDownload from './lw.button-download.vue'

@Component({
  name: 'lw-footer',
  components: { LwButtonDownload },
})
export default class LwFooter extends Vue {
  protected influencerUrl = null

  protected get baseUrl() {
    return this.influencerUrl ? `/${ this.influencerUrl }` : ''
  }

  created() {
    this.influencerUrl = this.$route.meta?.influencer
  }
}
