import { RouteConfig } from "vue-router"
import { influencers } from '@/constants'
import { ILwInfluencer } from '@/types'
import { LwLandingAnalytics } from '@/lw-landing.analytics'
import LwHome from '@/components/lw.home.vue'
import LwFooter from '@/components/lw.footer.vue'

const influencerRoutes: RouteConfig [] = influencers.map((i: ILwInfluencer) => {
  return {
    name: i.name,
    path: '/' + i.urlFragment,
    components: {
      content: LwHome,
      footer: LwFooter
    },
    meta: { influencer: i.urlFragment },
    beforeEnter(to, from, next){
      const influencer = to.meta?.influencer
      if (influencer){
        LwLandingAnalytics.setCampaignSource(influencer)
      }
      next()
    }
  } as RouteConfig
})

export const routes: RouteConfig[] = [
  {
    name: 'home',
    path: '/',
    components: {
      content: LwHome,
      footer: LwFooter
    },
  },
  ...influencerRoutes,
  {
    path: '*',
    redirect: '/',
  },
]
