






































































































































































import { Component, Vue } from "vue-property-decorator";
import { influencers } from "@/constants";
import { ILwInfluencer } from "@/types";
import LwButtonDownload from "./lw.button-download.vue";
import LwInfluencerCard from "./lw.influencer-card.vue";
import LwViewableImage from "./lw.viewable-image.vue";

@Component({
  name: "lw-home",
  components: { LwButtonDownload, LwInfluencerCard, LwViewableImage },
})
export default class LwHome extends Vue {
  protected influencer: ILwInfluencer | null = null;
  protected isImageModalActive = false;
  protected modalImage = "";

  protected get influencerUrl() {
    return this.$route.meta?.influencer;
  }

  created() {
    if (this.influencerUrl) {
      this.influencer =
        influencers.find((i: ILwInfluencer) => {
          return i.urlFragment === this.influencerUrl;
        }) || null;

      if (!this.influencer) {
        this.$router.push({ path: "/" });
      }
    }
  }

  viewImage(image: string) {
    this.modalImage = image;
    this.isImageModalActive = true;
  }
}
