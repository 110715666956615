









import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

@Component({
  name: 'lw-viewable-image',
  components: { },
})
export default class LwViewableImage extends Vue {
  @Prop({ required: true }) src!: string
  @Prop({ default: '' }) alt!: string

  @Emit()
  viewImage() {
    return this.src
  }
}
