
























import { Component, Vue, Prop } from 'vue-property-decorator'
import { ILwInfluencer } from '@/types'
import LwButtonDownload from '@/components/lw.button-download.vue'

@Component({
  name: 'lw-influencer-card',
  components: { LwButtonDownload },
})
export default class LwInfluencerCard extends Vue {
  @Prop({ required: true }) influencer!: ILwInfluencer

  protected get avatar() {
    return require(`@/assets/img/influencers/avatar_${ this.influencerUrl }.png`)
  }

  protected get banner() {
    return require(`@/assets/img/influencers/banner_${ this.influencerUrl }.png`)
  }

  protected get name() {
    return this.influencer?.name
  }

  protected get youtubeUrl() {
    return this.influencer?.youtubeUrl
  }

  protected get influencerUrl() {
    return this.$route.meta?.influencer
  }
}
